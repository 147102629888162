import { default as hadsP2fqln9mEJMeta } from "/app/src/domains/design_system/views/hads.vue?macro=true";
import { default as adminzDf7VxzI6CMeta } from "/app/src/domains/association/views/admin.vue?macro=true";
import { default as associationkx7cV3VSMMMeta } from "/app/src/domains/association/views/association.vue?macro=true";
import { default as allPmUDX0LTfZMeta } from "/app/src/domains/search/views/search/all.vue?macro=true";
import { default as tabkuD6YncnzGMeta } from "/app/src/domains/search/views/search/tab.vue?macro=true";
import { default as search9tDe0O6gjzMeta } from "/app/src/domains/search/views/search.vue?macro=true";
import { default as indexP4pgvlh7zsMeta } from "/app/src/domains/search/views/widget/index.vue?macro=true";
import { default as createPSkYOMD7ZUMeta } from "/app/src/domains/search/views/widget/create.vue?macro=true";
import { default as thematic7FAriC9qTAMeta } from "/app/src/domains/thematic/views/thematic.vue?macro=true";
import { default as legacy_45homeKlhtz31PaZMeta } from "/app/src/domains/home/views/legacy-home.vue?macro=true";
import { default as next_45homeePpdndrV4NMeta } from "/app/src/domains/home/views/next-home.vue?macro=true";
export default [
  {
    name: "hads",
    path: "/hads",
    component: () => import("/app/src/domains/design_system/views/hads.vue").then(m => m.default || m)
  },
  {
    name: "association_admin",
    path: "/associations/:organization/administration/ma-page-publique",
    meta: {"middleware":["secure-iframe"]},
    component: () => import("/app/src/domains/association/views/admin.vue").then(m => m.default || m)
  },
  {
    name: "association",
    path: "/associations/:organization",
    meta: {"middleware":["secure-iframe","fetch-organization-page"]},
    component: () => import("/app/src/domains/association/views/association.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/e/recherche",
    meta: {"middleware":["redirect-search"]},
    component: () => import("/app/src/domains/search/views/search.vue").then(m => m.default || m),
    children: [
  {
    name: "search_home",
    path: "",
    component: () => import("/app/src/domains/search/views/search/all.vue").then(m => m.default || m)
  },
  {
    name: "search_tab",
    path: ":search_tab",
    component: () => import("/app/src/domains/search/views/search/tab.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "widget",
    path: "/e/widget/:widget_type?/:search_tab?",
    meta: {"layout":"widget","middleware":["redirect-widget"]},
    component: () => import("/app/src/domains/search/views/widget/index.vue").then(m => m.default || m)
  },
  {
    name: "create_widget",
    path: "/e/creer-mon-widget/:search_tab?",
    component: () => import("/app/src/domains/search/views/widget/create.vue").then(m => m.default || m)
  },
  {
    name: "thematic_city_category_tags",
    path: "/e/reg/:place_region/dep/:place_department/ville/:place_city/cat/:category_tags",
    meta: {"middleware":["fetch-thematic-page"]},
    component: () => import("/app/src/domains/thematic/views/thematic.vue").then(m => m.default || m)
  },
  {
    name: "thematic_city_activity_type",
    path: "/e/reg/:place_region/dep/:place_department/ville/:place_city/act/:activity_type",
    meta: {"middleware":["fetch-thematic-page"]},
    component: () => import("/app/src/domains/thematic/views/thematic.vue").then(m => m.default || m)
  },
  {
    name: "thematic_city",
    path: "/e/reg/:place_region/dep/:place_department/ville/:place_city",
    meta: {"middleware":["fetch-thematic-page"]},
    component: () => import("/app/src/domains/thematic/views/thematic.vue").then(m => m.default || m)
  },
  {
    name: "thematic_department_category_tags",
    path: "/e/reg/:place_region/dep/:place_department/cat/:category_tags",
    meta: {"middleware":["fetch-thematic-page"]},
    component: () => import("/app/src/domains/thematic/views/thematic.vue").then(m => m.default || m)
  },
  {
    name: "thematic_department_activity_type",
    path: "/e/reg/:place_region/dep/:place_department/act/:activity_type",
    meta: {"middleware":["fetch-thematic-page"]},
    component: () => import("/app/src/domains/thematic/views/thematic.vue").then(m => m.default || m)
  },
  {
    name: "thematic_department",
    path: "/e/reg/:place_region/dep/:place_department",
    meta: {"middleware":["fetch-thematic-page"]},
    component: () => import("/app/src/domains/thematic/views/thematic.vue").then(m => m.default || m)
  },
  {
    name: "thematic_region_category_tags",
    path: "/e/reg/:place_region/cat/:category_tags",
    meta: {"middleware":["fetch-thematic-page"]},
    component: () => import("/app/src/domains/thematic/views/thematic.vue").then(m => m.default || m)
  },
  {
    name: "thematic_region_activity_type",
    path: "/e/reg/:place_region/act/:activity_type",
    meta: {"middleware":["fetch-thematic-page"]},
    component: () => import("/app/src/domains/thematic/views/thematic.vue").then(m => m.default || m)
  },
  {
    name: "thematic_region",
    path: "/e/reg/:place_region",
    meta: {"middleware":["fetch-thematic-page"]},
    component: () => import("/app/src/domains/thematic/views/thematic.vue").then(m => m.default || m)
  },
  {
    name: "thematic_event",
    path: "/e/evt/:ha_tags",
    meta: {"middleware":["fetch-thematic-page"]},
    component: () => import("/app/src/domains/thematic/views/thematic.vue").then(m => m.default || m)
  },
  {
    name: "thematic_activity_type",
    path: "/e/act/:activity_type",
    meta: {"middleware":["fetch-thematic-page"]},
    component: () => import("/app/src/domains/thematic/views/thematic.vue").then(m => m.default || m)
  },
  {
    name: "thematic_category_tags",
    path: "/e/cat/:category_tags",
    meta: {"middleware":["fetch-thematic-page"]},
    component: () => import("/app/src/domains/thematic/views/thematic.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/",
    meta: {"layout":"homepage","middleware":["secure-iframe"]},
    component: () => import("/app/src/domains/home/views/legacy-home.vue").then(m => m.default || m)
  },
  {
    name: "next-home",
    path: "/next-homepage",
    meta: {"layout":"homepage","middleware":["secure-iframe"]},
    component: () => import("/app/src/domains/home/views/next-home.vue").then(m => m.default || m)
  }
]