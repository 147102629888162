import pkg from "atob";
import { ConstantValues } from "../constants";
import useDebug from '~/domains/utils/composables/debug.composable';
const { atob: serverAtob } = pkg;
const debug = useDebug()

export const ensureResponse = (apiResponse) => {
  if (!apiResponse) {
    throw new Error("Auth api is not responding");
  } else if (apiResponse.error) {
    // If an error occurs in the auth API, the following payload is returned
    // {
    //   "error": "unauthorized_client",
    //   "error_description": "client_Id is not set" (optional)
    // }
    const errorTitle = apiResponse.error
    const errorDetail = apiResponse.error_description
    const message = errorTitle + (errorDetail ? `: ${errorDetail}` : "");
    throw new Error(message);
  }
};

export const isUserConnected = ($cookies) => {
  const accessToken = $cookies.get(ConstantValues.accessTokenCookieName);
  if (!accessToken) {
    return false;
  }
  const { hasUserId, isUnverified } = getJwtInfo(accessToken);
  return hasUserId && !isUnverified;
};

export const getJwtInfo = (jwt) => {
  // return if JWT is not a string
  if(typeof jwt !== 'string') return
  // Get base64 Encoder. `atob` is a native browser function, but you need to import it for the server
  const base64Decoder = import.meta.client ? atob : serverAtob;
  // Get encoded JWT Payload
  const encodedPayload = jwt.split(".")[1];
  // Decode JWT Payload
  const decodedPayload = JSON.parse(base64Decoder(encodedPayload));
  // get Expiration
  const tokenExpiration = decodedPayload.exp
  // get current unix time
  const utcTimestamp = Math.ceil(Date.now() / 1000);
  // get user roles
  const urs = decodedPayload.urs || [];
  const isAdmin = urs.includes(ConstantValues.orgAdmin) || urs.includes(ConstantValues.formAdmin);
  // debug
  debug.log(`[auth-service][JWT] Token expire in ${tokenExpiration.toFixed() - utcTimestamp}s`)
  return {
    // if exp claim is not present token is always valid
    isExpired: tokenExpiration <= utcTimestamp,
    isUnverified: decodedPayload.uvf === "1",
    hasUserId: !!decodedPayload.uid,
    isAdmin,
  };
};
