import { defineNuxtPlugin } from "#imports";
import { createStore } from "vuex";
import getStoreOrganizations from "@/store/organizations";
import getStoreAddress from "@/store/address";
import getStoreForms from "@/store/forms";
import getStoreUser from "@/store/user";
import { HttpService } from "@/services";
import useConfigEnv from "@/composables/useConfigEnv";
import useCookies from "@/composables/useCookies";

export default defineNuxtPlugin((nuxtApp) => {
  const configEnv = useConfigEnv();
  const { cookies } = useCookies();

  const httpApi = HttpService.createHttpApi(configEnv);
  const httpAuth = HttpService.createHttpProxyAuth(configEnv);
  HttpService.addHttpApiAuthorization(httpApi, configEnv, cookies);
  HttpService.addHttpProxyAuthAuthorization(httpAuth, cookies);
  HttpService.addErrorInterceptor(httpApi, configEnv, cookies);

  const address = getStoreAddress();
  const organizations = getStoreOrganizations({ httpApi });
  const forms = getStoreForms({ httpApi });
  const user = getStoreUser({ httpApi, httpAuth });

  const store = createStore({
    modules: {
      address,
      forms,
      organizations,
      user,
    },
  });

  nuxtApp.vueApp.use(store);

  if (import.meta.server) {
    nuxtApp.payload.vuex = store.state
  } else if (nuxtApp?.payload?.vuex) {
    store.replaceState(nuxtApp.payload.vuex)
  }
});
