import type { ActionTree, GetterTree, MutationTree } from "vuex";
import type { AxiosResponse, AxiosInstance } from "axios";
import type {
  Organization,
  newImage,
  OrganizationTypes,
} from "../models";
import type { RootState } from "./index";

interface OrganizationInterface {
  organization: Organization;
  types: OrganizationTypes[];
  newImage: null | newImage;
  thumbnailId: null | string;
}

const state = (): OrganizationInterface => ({
  organization: {},
  types: [],
  newImage: null,
  thumbnailId: null, // NOTE FOR FUTUR REBASE be careful to implement it in the new store' .ts file
});

export type OrganizationsState = ReturnType<typeof state>;

const getters: GetterTree<OrganizationsState, RootState> = {
  getOrganization: (state) => state.organization,
  getThumbnail: (state) => state.thumbnailId,
};

const mutations: MutationTree<OrganizationsState> = {
  FETCH_ORGANIZATION(state, organization) {
    state.organization = JSON.parse(
      JSON.stringify(organization)
    ) as Organization;
  },
  FETCH_TYPES(state, types) {
    state.types = types as OrganizationTypes[];
  },
  SET_NEW_IMAGE(state, image) {
    state.newImage = image as newImage;
  },
  RESET_NEW_IMAGE(state) {
    state.newImage = null;
  },
  SET_THUMBNAIL(state, id) {
    state.thumbnailId = id as string;
  },
};

const getActions = ({
  httpApi,
}: {
  httpApi: AxiosInstance;
}): ActionTree<OrganizationsState, RootState> => ({
  async fetchOrganization({ commit }, slug: string) {
    try {
      const response: AxiosResponse = await httpApi.get(
        `/organizations/${slug}/full`
      );
      const data: Organization = response.data;
      commit("FETCH_ORGANIZATION", data);
      return data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw error
    }
  },

  async fetchOrganizationTypes({ commit }) {
    try {
      const response: AxiosResponse = await httpApi.get(
        "/values/organization/categories"
      );
      const data: OrganizationTypes = response.data;
      commit("FETCH_TYPES", data);
      return data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw error
    }
  },

  async saveOrganizationData(
    { dispatch },
    { slug, payload }: { slug: string; payload: Record<string, unknown> }
  ) {
    try {
      await httpApi.put(`/organizations/${slug}`, payload);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw error
    }
    await dispatch("fetchOrganization", slug);
  },

  async updateBanner(
    { dispatch },
    { slug, file }: { slug: string; file: File }
  ) {
    const formData = new FormData();
    formData.append("file", file);
    try {
      await httpApi.post(`/organizations/${slug}/banner`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw error
    }
    await dispatch("fetchOrganization", slug);
    await dispatch("user/fetchUser", null, { root: true });
  },

  async deleteBanner({ dispatch }, slug) {
    try {
      await httpApi.delete(`/organizations/${slug}/banner`);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw error
    }
    await dispatch("fetchOrganization", slug);
    await dispatch("user/", null, { root: true });
  },

  async updateLogo({ dispatch }, { slug, file }) {
    const formData = new FormData();
    formData.append("file", file);
    try {
      await httpApi.post(`/organizations/${slug}/logo`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw error
    }
    await dispatch("fetchOrganization", slug);
    await dispatch("user/fetchUser", null, { root: true });
  },

  async deleteLogo({ dispatch }, slug: string) {
    try {
      await httpApi.delete(`/organizations/${slug}/logo`);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw error
    }
    await dispatch("fetchOrganization", slug);
    await dispatch("user/fetchUser", null, { root: true });
  },
  async addImage(
    { dispatch, commit },
    { slug, file, caption }: { slug: string; file: File; caption: string }
  ) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("description", caption);
    try {
      await httpApi.post(`/organizations/${slug}/image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw error
    }
    commit("RESET_NEW_IMAGE");
    await dispatch("fetchOrganization", slug);
  },
  async deleteImage({ dispatch }, { slug, id }: { slug: string; id: string }) {
    try {
      await httpApi.delete(`/organizations/${slug}/image/${id}`);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw error
    }
    // TODO see where deleteVideo should be used and re-add tracking
    /* await this.app.segment.track('Organization Page Updated', {
      organization_slug: slug,
      update_type: ['Retrait Image']
    }) */
    await dispatch("fetchOrganization", slug);
  },
  setImage({ commit }, { image }) {
    commit("SET_NEW_IMAGE", image);
  },
  async addVideo(
    { dispatch },
    { slug, url }: { slug: string; url: string }
  ): Promise<any> {
    try {
      await httpApi.post(`/organizations/${slug}/video`, { url });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw error
    }
    await dispatch("fetchOrganization", slug);
  },
  async deleteVideo({ dispatch }, { slug, id }: { slug: string; id: string }) {
    try {
      await httpApi.delete(`/organizations/${slug}/video/${id}`);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw error
    }
    // TODO see where deleteVideo should be used and re-add tracking
    /* await this.app.segment.track('Organization Page Updated', {
      organization_slug: slug,
      update_type: ['Retrait Vidéo']
    }) */
    await dispatch("fetchOrganization", slug);
  },
});

export default ({ httpApi }: { httpApi: AxiosInstance }) => ({
  state,
  getters,
  mutations,
  actions: getActions({ httpApi }),
  namespaced: true,
});
