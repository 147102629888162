import { defineNuxtRouteMiddleware, navigateTo } from '#imports'

const matchCaret = /\/--*|--*\/--*|--*\/|--*$/g

export const pathIsExplore = (path: string): boolean =>
  !!path.startsWith('/e/')
export const pathContainCaret = (path: string): boolean =>
  !!path.match(matchCaret)?.length
export const pathReplaceCaret = (path: string): string =>
  path.replace(matchCaret, '/')

export default defineNuxtRouteMiddleware((to) => {
  const { path, query, hash } = to

  let nextPath: string = null

  // 1. Remove dirty char `-` before and after url params
  if (pathContainCaret(path) && pathIsExplore(path)) {
    nextPath = pathReplaceCaret(path)
  }

  // 2. Remove `/` at end of url
  if (path !== '/' && path.endsWith('/')) {
    nextPath = path.replace(/\/+$/, '') || '/'
  }

  // 3. If nextPath was defined, redirect with code 301
  if (nextPath) {
    const nextRoute = { path: nextPath, query, hash }
    return navigateTo(nextRoute, { redirectCode: 301 })
  }
})
