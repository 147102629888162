import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_8BKffvaEi5 from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_iGLjBbor9q from "/app/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import algolia_client_iG3l2PfW4z from "/app/src/plugins/algolia-client.ts";
import error_handler_eQzajpVw3N from "/app/src/plugins/error-handler.ts";
import gtm_client_Hp0PRXEnoA from "/app/src/plugins/gtm.client.ts";
import ha_plugin_AVhZMXSXYy from "/app/src/plugins/ha-plugin.ts";
import recaptcha_client_M2Ktvz7GAv from "/app/src/plugins/recaptcha.client.js";
import segment_client_q5KxBsdXgL from "/app/src/plugins/segment.client.ts";
import stores_YLLKqlv59i from "/app/src/plugins/stores.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  plugin_iGLjBbor9q,
  algolia_client_iG3l2PfW4z,
  error_handler_eQzajpVw3N,
  gtm_client_Hp0PRXEnoA,
  ha_plugin_AVhZMXSXYy,
  recaptcha_client_M2Ktvz7GAv,
  segment_client_q5KxBsdXgL,
  stores_YLLKqlv59i
]